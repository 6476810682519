@import "background-colors.css";
@import "fonts.css";
@import "text-colors.css";
@import "NewsNavigation.css";
@import "NewsCards.css";
@import "videos.css";
@import "packages.css";

.p-lr-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.opacity-99 {
    opacity: 0.99 !important;
}

.pipe-black:after {
    content: "|";
    padding-left: 15px;
    color: #000000 !important;
}

.pipe-white:after {
    content: "|";
    padding-left: 15px;
    color: #FFFFFF !important;
}

.navbar-light .nav-link.active, .nav-link:active {
    color: #ED2F59 !important;
}

.navbar-transparent .nav-link.active, .nav-link:active {
    color: #ED2F59 !important;
}

.btn-violet {
    background-color: #7A28CB;
    color: #FFFFFF;
}

.btn-violet.active {
    background-color: #FFFFFF;
    border: 1px solid #7A28CB;
    color: #7A28CB !important;
}

.btn-violet:hover {
    background-color: #FFFFFF;
    border: 1px solid #7A28CB !important;
    color: #7A28CB !important;
}

.btn-pink {
    background-color: #ED2F59;
    color: #FFFFFF;
}

.btn-pink.active {
    background-color: #FFFFFF;
    border: 1px solid #ED2F59;
    color: #ED2F59 !important;
}

.btn-pink:hover {
    background-color: #FFFFFF;
    border: 1px solid #ED2F59 !important;
    color: #ED2F59 !important;
}

.btn-violet-outline {
    background-color: transparent;
    border: 1px solid #7A28CB !important;
    color: #7A28CB !important;
    cursor: pointer;
}

.btn-violet-outline.active {
    background-color: #FFFFFF;
    border: 1px solid #7A28CB;
    color: #7A28CB !important;
}

.btn-violet-outline:hover {
    background-color: #7A28CB;
    color: #FFFFFF;
}

.btn-violet-outline:hover > * {
    color: #FFFFFF;
}

.btn-white-outline {
    background-color: transparent;
    border: 1px solid #FFFFFF !important;
    color: #FFFFFF !important;
    cursor: pointer;
}

.btn-white-outline.active {
    background-color: #FFFFFF;
    border: 1px solid #FFFFFF;
    color: #FFFFFF !important;
}

.btn-white-outline:hover {
    background-color: #FFFFFF;
    color: #000000;
}

.btn-white-outline:hover > * {
    color: #000000;
}

.banner {
    width: 100%;
    /*height: 100vh;*/
    margin-top: -75px;
}

.banner-image {
    background-image: url("../images/banner.jpg");
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
}

.banner-text {
    /*background-color: rgba(255, 255, 255, 0.8);*/
    /*backdrop-filter: blur(5px);*/
    padding-top: 150px;
    height: inherit;
    color: #000000;
}

.custom-text-input {
    background-color: transparent;
    outline: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.5);
}

.custom-text-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFFFFF;
    opacity: 0.5; /* Firefox */
}

.custom-text-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #FFFFFF;
}

.custom-text-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #FFFFFF;
}

.contact-us-text-input {
    background-color: transparent;
    outline: rgba(22, 40, 203, 0.2);
    border-color: rgba(22, 40, 203, 0.2);
}

.contact-us-text-input:focus, .contact-us-text-input:active {
    background-color: transparent;
    outline: #7A28CB;
    border-color: #7A28CB;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.contact-us-text-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1C0B19;
    opacity: 0.5; /* Firefox */
}

.contact-us-text-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #1C0B19;
}

.contact-us-text-input::-ms-input-placeholder { /* Microsoft Edge */
    color: #1C0B19;
}

.nav-tabs .nav-item .nav-link {
    color: #1C0B19;
    background-color: #fff;
    border-color: #7A28CB;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #fff;
    background-color: #ED2F59;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:active {
    color: #fff !important;
    background-color: #ED2F59;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link:focus {
    color: #fff !important;
    background-color: #ED2F59;
    border-color: transparent;
}

.nav-tabs {
    border-bottom: none;
}

.nav-tabs .nav-link:hover {
    color: #fff;
    background-color: #ED2F59;
    border-color: transparent;;
}

.contact-us-container {
    margin-bottom: 30em;
}

.contact-us-box {
    position: absolute;
    z-index: 1;
}

/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
    /*background-color: #7A28CB;*/
    /*width: 300px;*/
    /*height: 200px;*/
    /*border: 1px solid #f1f1f1;*/
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    /*height: 100%;*/
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    /*height: 100%;*/
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    cursor: pointer;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
    background-color: #7A28CB;
    color: #FFFFFF;
}

/* Style the back side */
.flip-card-back {
    background-color: #FFFFFF;
    color: #7A28CB;
    transform: rotateY(180deg);
}

.shadow-md {
    box-shadow: 0 0.250rem 0.25rem rgb(0 0 0 / 15%) !important;
}

.mt-n1 {
    margin-top: -0.25rem !important;
}

.mt-n2 {
    margin-top: -0.5rem !important;
}

.mt-n3 {
    margin-top: -1rem !important;
}

.mt-n4 {
    margin-top: -1.5rem !important;
}

.mt-n5 {
    margin-top: -3rem !important;
}

.text-four-line-ellipsis {
    display: -webkit-box;
    /*max-width: 200px;*/
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.ad-height {
    height: 250px !important;
}

.allNewsMainPictureStyles{
    height: 422px;
    width: 1000px
}

.miniPictureStyles{
    height: 130px;
}

.verticalPictureStyles{
    height: 552px;
}


@media only screen and (max-width: 800px) {

    .banner {
        width: 100%;
        /*height: 100vh;*/
        margin-top: -120px;
    }

    .banner-image {
        background-image: url("../images/banner.jpg");
        width: 100%;
        height: auto;
        background-repeat: no-repeat;
    }

    .banner-text {
        /*background-color: rgba(255, 255, 255, 0.8);*/
        /*backdrop-filter: blur(5px);*/
        padding-top: 150px;
        height: inherit;
        color: #000000;
    }

    .pipe-white:after {
        content: "";
    }

    .pipe-black:after {
        content: "";
    }

    .navbar-collapse {
        position: absolute;
        z-index: 1;
        top: 4em;
        background-color: #FFFFFF;
        width: 100%;
        left: 0;
        padding-left: 1em;
        padding-bottom: 20px;
        padding-right: 1em;
    }

    .contact-us-container {
        margin-bottom: auto;
    }

    .contact-us-box {
        position: relative;
    }

}
