
.card-image-radius-zero {
    border-radius: 0;
}

.w-fit-content {
    width: fit-content !important;
}

.news-overlay {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    /*background-color: rgba(0, 0, 0, 0.6);*/
    /*background-image: linear-gradient(to top, rgba(0, 0, 0, 0.6), rgba(59, 59, 59, 0.5), rgba(119, 119, 119, 0.4), rgba(185, 185, 185, 0.3), rgba(0, 0, 0, 0));*/
    /*height: 40%;*/
    margin-top: auto;
    margin-bottom: 0;
}

.video-overlay {
    background: rgba(0,0,0, 0.60);
    overflow: hidden;
    z-index: 140;
}

.video-overlay-side-image {
    background: rgba(0,0,0, 0.60);
    overflow: hidden;
    z-index: 140;
    /*width: 92%;*/
    /*margin-left: 12px;*/
}

.slick-arrow .slick-prev{
    top: 135px
}

.slick-arrow .slick-next{
    top: 135px
}

.slick-slide {
    margin: 0 5px;
}

/* the parent */
.slick-list {
    margin: 0 25px;
    /*overflow-x: scroll !important;*/
}

.padding-right-zero {
    padding-right: 0 !important;
}

.padding-left-zero {
    padding-left: 0 !important;
}


.marquee-padding {
    padding-top: 10px;
    padding-bottom: 10px;
}

.margin-left-12 {
    margin-left: 12px;
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex !important;
    margin-left: 0 !important;
    margin-right: auto;
}

.slick-prev, .slick-next {
    font-size: 0;
    top: 230px;
    line-height: 0;
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.margin-left-right-zero {
    margin-left: 0;
    margin-right: 0;
}

.cursor-pointer {
    cursor: pointer;
}

.zIndexClass {
    z-index: 1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .newsCardOneStyles {
        width: auto !important;
        height: 214px !important;
        object-fit: cover !important;
        max-width: 100%;
    }

    .newsCardTwoStyles {
        width: auto !important;
        height: 214px !important;
        object-fit: cover !important;
        max-width: 100%;

    }

    .newsCardThreeStyles {
        width: auto !important;
        height: 214px !important;
        object-fit: cover !important;
        max-width: 100%;

    }

    .newsCardFourStyles {
        width: auto !important;
        object-fit: cover !important;
        height: 214px !important;
        max-width: 100%;

    }

    .newsCardFiveStyles {
        object-fit: cover !important;
        width: auto !important;
        height: 214px !important;
        max-width: 100%;

    }

    .newsCardSixStyles {
        width: 320px !important;
        height: 214px !important;
        object-fit: cover !important;
        max-width: 100%;

    }

    .newsCardSevenStyles {
        object-fit: cover !important;
        width: auto !important;
        max-width: 100%;
        height: 214px !important;
    }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .newsCardOneStyles {
        object-fit: cover !important;
        width: auto !important;
        height: 214px !important;
    }

    .newsCardTwoStyles {
        object-fit: cover !important;

        width: auto !important;
        height: 214px !important;
    }

    .newsCardThreeStyles {
        object-fit: cover !important;
        width: auto !important;
        height: 214px !important;
    }

    .newsCardFourStyles {
        object-fit: cover !important;

        width: auto !important;
        height: 214px !important;
    }

    .newsCardFiveStyles {
        object-fit: cover !important;
        width: auto !important;
        height: 214px !important;
    }

    .newsCardSixStyles {
        object-fit: cover !important;

        width: auto !important;
        height: 214px !important;
    }

    .newsCardSevenStyles {
        object-fit: cover !important;
        width: auto !important;
        height: 214px !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .newsCardOneStyles {
        width: 190px !important;
        height: 251px !important;
        object-fit: cover !important;

    }

    .newsCardTwoStyles {
        object-fit: cover !important;

        width: 408px !important;
        height: 214px !important;
    }

    .newsCardThreeStyles {
        object-fit: cover !important;

        width: 525px !important;
        height: 475px !important;
    }

    .newsCardFourStyles {
        object-fit: cover !important;

        width: 763px !important;
        height: 378px !important;
    }

    .newsCardFiveStyles {
        width: 201px !important;
        object-fit: cover !important;
        height: 164px !important;
    }

    .newsCardSixStyles {
        width: 513px !important;
        object-fit: cover !important;
        height: 376px !important;
    }

    .newsCardSevenStyles {
        object-fit: cover !important;
        /*width: 903px !important;*/
        max-height: 378px !important;
        max-width: 100%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .newsCardOneStyles {
        object-fit: cover !important;
        width: 271px !important;
        height: 251px !important;
    }

    .newsCardTwoStyles {
        object-fit: cover !important;
        width: 555px !important;
        height: 214px !important;
    }

    .newsCardThreeStyles {
        object-fit: cover !important;
        width: 725px !important;
        height: 475px !important;
    }

    .newsCardFourStyles {
        object-fit: cover !important;
        width: 763px !important;
        height: 378px !important;
    }

    .newsCardFiveStyles {
        object-fit: cover !important;
        width: 201px !important;
        height: 164px !important;
    }

    .newsCardSixStyles {
        /*not important*/
        /*object-fit: cover !important;*/
        /*width: 513px !important;*/
        /*height: 156px !important;*/
    }

    .newsCardSevenStyles {
        object-fit: cover !important;
        width: 903px !important;
        height: 378px !important;
    }
}
