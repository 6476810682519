.slick-prev:before {
    /*content: '>' !important;*/
    color: #9A9AB0 !important;
    background-color: transparent !important;
}
.slick-next:before {
    /*content: '>' !important;*/
    color: #9A9AB0 !important;
    background-color: transparent !important;
}
