/* Over-riding pre-written modal CSS for more custom styling */

.modal-backdrop {
    --bs-backdrop-opacity: 0.75;
    width: 100%;
    height: 100%;
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    /* width: 60%; */
    /* To center the modal, used the auto property. */
    margin: auto;
}

@media (min-width: 1200px) {
    .custom-col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333333% !important;
    }
}

@media (min-width: 992px) {
    .custom-col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333333% !important;
    }
}

@media (min-width: 768px) {
    .custom-col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333% !important;
    }
}

.custom-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333% !important;
}


@media (min-width: 576px) {
    .custom-col-sm-12 {
        flex: 1 0 auto;
        width: 100% !important;
        margin: auto;
    }
}

.custom-col-sm-12 {
    flex: 1 0 auto;
    width: 100% !important;
    margin: auto;

}