.bg-pink {
    background-color: #ED2F59;
}

.bg-light-pink {
    background-color: #FFF3F7;
}

.bg-light-pink-gradient {
    background: rgb(255, 243, 247);
    background: linear-gradient(180deg, rgba(255, 243, 247, 1) 0%, rgba(255, 255, 255, 1) 100%);
}

.bg-violet {
    background-color: #7A28CB;
}

.bg-yellow {
    background-color: #E9D758;
}

.bg-xiketic {
    background-color: #1C0B19;
}

.border-pink {
    border-color: #ED2F59 !important;
}

.border-violet {
    border-color: #7A28CB !important;

}

.border-yellow {
    border-color: #E9D758 !important;
}

.border-xiketic {
    border-color: #1C0B19 !important;
}

.bg-blue {
    background-color: #0088FF;
}

.bg-light-blue-one{
    background-color: #49CFE8
}

.bg-white{
    background-color:  #F5F5F5;
}
