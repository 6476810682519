@import url('//fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('//fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');

.bebas {
    font-family: 'Bebas Neue', sans-serif;
}

.sora {
    font-family: 'Sora', sans-serif;
}

* {
    font-family: 'Sora', sans-serif;
}

.font-100 {
    font-weight: 100 !important;
}

.font-200 {
    font-weight: 200 !important;
}

.font-300 {
    font-weight: 300 !important;
}

.font-400 {
    font-weight: 400 !important;
}

.font-500 {
    font-weight: 500 !important;
}

.font-600 {
    font-weight: 600 !important;
}

.font-700 {
    font-weight: 700 !important;
}

.font-800 {
    font-weight: 800 !important;
}

.font-900 {
    font-weight: 900 !important;
}

.font-size-8 {
    font-size: 8px !important;
}

.font-size-10 {
    font-size: 10px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-20 {
    font-size: 20px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-26 {
    font-size: 26px !important;
}

.font-size-28 {
    font-size: 28px !important;
}

.font-size-30 {
    font-size: 30px !important;
}

.font-size-40 {
    font-size: 40px !important;
}

.font-size-50 {
    font-size: 50px !important;
}

.font-size-70 {
    font-size: 70px !important;
}
.font-size-80 {
    font-size: 80px !important;
}
.font-size-90 {
    font-size: 90px !important;
}
