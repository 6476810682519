.packages__firstPackage{
    /*border-radius: 5px;*/
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 7px;
}


.packages__thirdPackage{
    /*border-radius: 5px;*/
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 7px;
}

.packages__firstPackage__mobile,
.packages__thirdPackage__mobile{
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 7px;
}

.middle__package{
    border-radius:25px;
    background: linear-gradient(62.44deg, #ED2F59 31.15%, #652BA6 142.13%);
    /*border-color: purple;*/
    border: 1px solid purple;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 7px;

}

.purple__linearGradient{
    background: rgb(246,244,254);
}

.purpleButton{
    color: rgb(128, 78, 209);
    border-radius: 20px;
    border: 0;
    padding: 5px 5px 5px 0;
}

.purpleButton__whiteText{
    color: white;
    border-radius: 20px;
    border: 0;
    padding: 15px 15px 15px 10px;
    background: linear-gradient(110.6deg, rgb(184, 142, 252) 3.2%, rgb(104, 119, 244) 100.2%);
}

.purpleRightArrow{
    border-radius: 50%;
    width: 25px;
    height: 25px;
}

.linearGradient__text{
    background-clip: text;
    -webkit-background-clip: text; /* For Safari */
    color: transparent;
    background-image: linear-gradient(to right, rgb(128, 78, 209),rgb(232, 179, 255));
    font-weight: 900;
}


.head-text {
    position: relative;
    bottom: 20px;
    left: 27%;
}
.text-on-image {
    position: absolute;
    right: 5%;
    bottom: 15%;
}

.cross {
    position: relative;
    display: inline-block;
}
.cross::before, .cross::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
}
.cross::before {
    border-bottom: 3px solid #ED2F59;
    -webkit-transform: skewY(-10deg);
    transform: skewY(-10deg);
}

.customHR{
    color: inherit;
    border: 0;
    border-top: 1px solid;
    opacity: 0.25;
    margin: 2px;
}

.third-hr-gradient {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to left,  #ED2F59,rgb(232, 179, 255));
}

.first-hr-gradient {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to left, rgb(128, 78, 209),rgb(232, 179, 255));
}

.second-hr-gradient {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to left, white,rgb(232, 179, 255));
}

 #controlled-tab-example-tab-bronze{
     background-color: #772f1a;
     background-image: linear-gradient(315deg, #772f1a 0%, #f2a65a 74%);
}

#controlled-tab-example-tab-silver {
    background-color: #5b6467;
    background-image: linear-gradient(315deg, #5b6467 0%, #8b939a 74%);
}

#controlled-tab-example-tab-gold{
    background-color: #fec84e;
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea8 74%);
}

/*.nav-tabs .nav-item .nav-link {
    color: white;
}*/

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    /*background-color: purple;*/
    /*border: 0.25px solid white;*/
    /*box-shadow: rgba(102, 51, 153, 0.8) 0px 1px 2px 0px, rgba(102, 51, 153, 0.8) 0px 1px 3px 1px;*/
    font-weight: 800;
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    width: 95%;
    border-radius: 25px;
}
