.text-pink {
    color: #ED2F59;
}

.text-violet {
    color: #7A28CB;
}

.text-yellow {
    color: #E9D758;
}

.text-xiketic {
    color: #1C0B19;
}

.text-dark-green{
    color: #2C3C43;
}

.text-white{
    color: #FEFEFE;
}

.text-grey{
    color: #9A9AB0;
}

.text-dark-blue{
    color: #11142D
}
