.boxColor {
    background-color: transparent;
    border: 2px solid #ED2F59;
}
.boxColor:hover {
    background-color: #ED2F59;
    border: 2px solid #7A28CB;
    cursor: pointer;
}
.iconColor {
    color: #ED2F59;
    cursor: pointer;
}
.boxColor:hover .iconColor {
    color: #FFFFFF;
}
