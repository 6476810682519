.allNewsMainPictureStyles{
    height: 422px;
    width: 1000px
}

.miniPictureStyles{
    height: 130px;
}

.verticalPictureStyles{
    height: 552px;
}
